import { type ChangeEvent, type FormEvent, memo, useCallback, useReducer } from 'react';
import { TextInput, Button, Spinner, PhoneInput } from '@components/common';
import { useHandleSubmitContactForm, useLatest } from '@hooks/index.tsx';
import ArrowRight from '@assets/arrow-right.svg?react';
import styles from './ContactSectionForm.module.scss';
import type { CountryData } from 'react-phone-input-2';

type Reducer = {
    name: string;
    website: string;
    phone: string;
    country: string;
    email: string;
    description: string;
};

const redirectUrl = '/danke/';

const phoneInputProps = { required: true };

const ContactSectionForm = () => {
    const [form, updateForm] = useReducer(
        (prev: Reducer, next: Partial<Reducer>) => {
            return { ...prev, ...next };
        },
        { name: '', website: '', phone: '', country: '', email: '', description: '' } as Reducer,
    );

    const formRef = useLatest(form);

    const onNameChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => updateForm({ name: e.target.value }),
        [updateForm],
    );
    const onWebsiteChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => updateForm({ website: e.target.value }),
        [updateForm],
    );
    const onPhoneChange = useCallback(
        (value: string, data: CountryData) => updateForm({ phone: value, country: data.name }),
        [updateForm],
    );
    const onEmailChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => updateForm({ email: e.target.value }),
        [updateForm],
    );
    const onDescriptionChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => updateForm({ description: e.target.value }),
        [updateForm],
    );

    const onSuccess = useCallback(() => {
        window.location.href = `${redirectUrl}?name=${formRef.current.name}&email=${formRef.current.email}&phone=${formRef.current.phone}`;
    }, [formRef]);

    const {
        isLoading,
        hasError,
        onSubmit: onSubmitForm,
        onInvalid,
    } = useHandleSubmitContactForm({ form, onSuccess });

    const onSubmit = useCallback(
        async (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            await onSubmitForm();
        },
        [form],
    );
    return (
        <form
            className={styles.contactSectionForm}
            onSubmit={onSubmit}
            onInvalidCapture={onInvalid}>
            <TextInput
                className={styles.contactSectionInput}
                placeholder="Name"
                value={form.name}
                onChange={onNameChange}
                required
            />
            <TextInput
                className={styles.contactSectionInput}
                placeholder="E-Mail"
                value={form.email}
                onChange={onEmailChange}
                type="email"
                required
            />
            <PhoneInput
                name="phone"
                containerClass={styles.phoneInputWrapper}
                placeholder="Telefon"
                value={form.phone}
                onChange={onPhoneChange}
                inputProps={phoneInputProps}
                enableSearch
            />
            <TextInput
                className={styles.contactSectionInput}
                placeholder="Website"
                value={form.website}
                onChange={onWebsiteChange}
            />
            <TextInput
                className={styles.contactSectionFormTextArea}
                textarea
                placeholder="Deine Nachricht"
                value={form.description}
                onChange={onDescriptionChange}
                required
            />

            <span className={styles.contactSectionFormText}>
                Mit dem Abschicken dieses Formulars stimmst du unseren{' '}
                <a href="/datenschutz/" target="_blank">
                    Datenschutzbedingungen
                </a>{' '}
                zu.
            </span>

            {hasError && (
                <span className={styles.contactFormErrorText}>
                    Something went wrong. Please try again.
                </span>
            )}

            <Button className={styles.contactSectionFormButton} disabled={isLoading} withLogo>
                {isLoading && <Spinner />} Nachricht schicken
            </Button>
        </form>
    );
};

export default memo(ContactSectionForm);
